import React, { lazy, useState } from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./comp-styles/Footer.css";
import NewLetSubmitBtn from "../assets/submit-arrow.webp";

const NewsLetModal = lazy(() => import("./NewsLetModal"));

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      await axios.post("/leads/subscribe", { email: values.email });
      resetForm();
      setModalMessage("Subscribed to the newsletter.");
    } catch (error) {
      console.error("Error subscribing to the newsletter:", error);
      setModalMessage("Failed to subscribe to the newsletter.");
    } finally {
      setIsModalOpen(true); // Show the modal
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handlePhoneClick = async (phoneNumber, event) => {
    event.preventDefault(); // Prevent default link behavior

    try {
      // Copy the phone number to clipboard
      await navigator.clipboard.writeText(phoneNumber);
      alert("Phone number copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy phone number:", error);
    }

    // Open the phone dialer with the number
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    <footer>
      <div className="footer-container ">
        <div className="contact-info">
          <span className="footer-link-heads">Contact Us:</span>
          <p>
            Email:
            <a
              href="mailto:wecare@anandacribs.com"
              target="_blank"
              rel="noopener noreferrer"
              className="contact-info-links"
            >
              {" "}
              wecare@anandacribs.com
            </a>
          </p>
          <p>
            Phone:{" "}
            <a
              href="tel:+919324605093"
              onClick={(event) => handlePhoneClick("+919324605093", event)}
              className="contact-info-links"
            >
              +91-932-460-5093
            </a>
          </p>
          <p>
            Address:
            <a
              href="https://www.google.com/maps/place/19%C2%B014'31.5%22N+73%C2%B008'49.3%22E/@19.2420685,73.1469379,20z/data=!4m11!1m6!3m5!2zMTnCsDE0JzMxLjUiTiA3M8KwMDgnNDkuMyJF!8m2!3d19.2420817!4d73.1470379!10e5!3m3!8m2!3d19.2420817!4d73.1470379?entry=ttu"
              target="_blank"
              rel="noreferrer"
              className="contact-info-links"
            >
              {" "}
              Thane, Maharashtra.
            </a>
          </p>
        </div>
        <div className="quick-links">
          <span className="footer-link-heads">Quick Links</span>
          <ul>
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/faq">FAQ</Link>
            </li>
            <li>
              <Link to="/terms">Terms of Service</Link>
            </li>
            <li>
              <Link to="/privacy">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className="social-media">
          <span className="footer-link-heads">Follow Us</span>
          <ul>
            <li>
              <a
                href="https://www.facebook.com/AnandaCribs/"
                target="_blank"
                rel="noreferrer"
                aria-label="Follow us on Facebook"
                className="social-links"
              >
                <FaFacebook size={"2.5rem"} />
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/anandacribs/"
                target="_blank"
                rel="noreferrer"
                aria-label="Follow us on Instagram"
                className="social-links"
              >
                <FaInstagram size={"2.5rem"} />
              </a>
            </li>
          </ul>
        </div>
        <div className="newsletter">
          <span className="footer-link-heads">Subscribe to Our Newsletter</span>
          <Formik
            initialValues={{ email: "" }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="input-wrapper">
                  <Field
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    autoComplete="Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                  <button
                    type="submit"
                    className="footer-cta-button"
                    disabled={isSubmitting}
                  >
                    <img src={NewLetSubmitBtn} alt="Submit" />
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="legal-info">
        <p>&copy; 2024 Your Website. All rights reserved.</p>
        <p>Terms of Service | Privacy Policy | Sitemap</p>
      </div>
      <NewsLetModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        message={modalMessage}
      />
    </footer>
  );
};

export default Footer;
