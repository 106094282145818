import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { lazy } from "react";

const ScrollUpArrow = lazy(() => import("./components/ScrollArrow"));

export default function Layout() {
  return (
    <main>
      <Navbar />
      <ScrollUpArrow />
      <Outlet />
      <Footer />
    </main>
  );
}
