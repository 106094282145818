import { createContext, useState, useEffect } from "react";


// Create a new context
export const StateContext = createContext();

// Create a provider component for the loading context
export const StateProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [getQuote, setGetQuote] = useState(false);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    },300); // Adjust the delay time as needed

    return () => clearTimeout(timer);
  }, []);

  const howItWorksData = [
    {
      stepNumber: 1,
      title: "Initiation",
      description: [
        "Customers provide their contact information or reach out to us for assistance.",
        "This step ensures we can easily reach out to them and understand their requirements.",
      ],
    },
    {
      stepNumber: 2,
      title: "Needs Assessment",
      description: [
        "We listen to our customers' needs and preferences.",
        "Understanding their requirements helps us tailor our services to their specific needs.",
      ],
    },

    {
      stepNumber: 3,
      title: "Paperwork Assistance",
      description: [
        "We assist customers with all necessary paperwork until they obtain the keys to their new home.",
        "Our support ensures a smooth and hassle-free buying experience for our customers.",
      ],
    },
    {
      stepNumber: 4,
      title: "Brokerage-Free Support, Your Perfect Match",
      description: [
        "Experience the magic of zero brokerage while benefiting from our simple, yet impactful support, ensuring a smooth property journey without the extra cost.",
      ],
    },
  ];

  return (
    <StateContext.Provider
      value={{
        isLoading,
        setIsLoading,
        howItWorksData,
        isModalOpen,
        setIsModalOpen,
        getQuote,
        setGetQuote,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};
