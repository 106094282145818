import "./App.css";
import GridSkeleton from "./components/GridSkeleton";
import React, { Suspense, useEffect, useState, useCallback } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./Layout";
import axios from "axios";
import { getProjectData, setProjectData } from "./utils/idb";
import LoadingPage from "./components/Loading";
import IndexPage from "./components/IndexPage.js";

const ProjectGrid = React.lazy(() => import("./components/ProjectGrid.js"));
const ContactUs = React.lazy(() => import("./components/ContactUs.js"));
const AboutUs = React.lazy(() => import("./components/AboutUs.js"));
const NotFound = React.lazy(() => import("./components/NotFound.js"));

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

function App() {
  const [projectData, setProjectDataState] = useState([]);
  const [indexPageLoaded, setIndexPageLoaded] = useState(false);
  const [loadingProjectData, setLoadingProjectData] = useState(true);
  const location = useLocation();

  // Fetch project data when IndexPage is loaded
  useEffect(() => {
    const fetchProjectData = async () => {
      setLoadingProjectData(true);
      const cachedData = await getProjectData();
      if (cachedData) {
        setProjectDataState(cachedData);
      } else {
        try {
          const response = await axios.get("/projectData");
          setProjectDataState(response.data);
          await setProjectData(response.data);
        } catch (error) {
          console.error("Error fetching project data:", error);
        }
      }
      setLoadingProjectData(false);
    };

    if (indexPageLoaded) {
      fetchProjectData();
    } else {
      // Ensure that the project data is fetched on index page load
      fetchProjectData();
    }
  }, [indexPageLoaded]);

  // Handler to mark IndexPage as loaded
  const handleIndexPageLoad = useCallback(() => {
    setIndexPageLoaded(true);
  }, []);

  const getFallbackComponent = () => {
    if (location.pathname === "/projects") {
      return loadingProjectData ? <GridSkeleton /> : <LoadingPage />;
    }
    return <LoadingPage />;
  };

  return (
    <main>
      <Suspense fallback={getFallbackComponent()}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<IndexPage onLoad={handleIndexPageLoad} />} />
            <Route
              path="/projects"
              element={<ProjectGrid projectData={projectData} />}
            />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </main>
  );
}

export default App;
