import React from "react";
import "./comp-styles/GridSkeleton.css";
const GridSkeleton = () => {
  return (
    <div className="container">
      <div className="row skeleton-row">
        {/* Skeleton for the first card */}
        <div className="col-md-4 mb-4">
          <div className="skeleton-card"></div>
        </div>
        {/* Skeleton for the second card */}
        <div className="col-md-4 mb-4">
          <div className="skeleton-card"></div>
        </div>
        {/* Skeleton for the third card */}
        <div className="col-md-4 mb-4">
          <div className="skeleton-card"></div>
        </div>
        <div className="col-md-4 mb-4">
          <div className="skeleton-card"></div>
        </div>
        {/* Skeleton for the second card */}
        <div className="col-md-4 mb-4">
          <div className="skeleton-card"></div>
        </div>
        {/* Skeleton for the third card */}
        <div className="col-md-4 mb-4">
          <div className="skeleton-card"></div>
        </div>
        {/* Add more skeleton cards as needed */}
      </div>

      {/* Add more skeleton cards as needed */}
    </div>
  );
};

export default GridSkeleton;
