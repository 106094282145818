import { lazy, useContext, useEffect } from "react";

import DesCTA from "./DesCTA";

import { StateContext } from "../StateContext";
import { Helmet } from "react-helmet";

const HowItWorks = lazy(() => import("./HowItWorks"));
const Features = lazy(() => import("./Features"));

const IndexPage = ({ onLoad }) => {
  const { howItWorksData } = useContext(StateContext);
  useEffect(() => {
    // Notify parent component when IndexPage has finished loading
    onLoad();
  }, [onLoad]);

  <Helmet>
    <link rel="canonical" href="https://anandacribs.com/" />
    <meta name="robots" content="index, follow" />
  </Helmet>;

  return (
    <>
      <DesCTA />
      <Features />
      <HowItWorks data={howItWorksData} />
    </>
  );
};

export default IndexPage;
