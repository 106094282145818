import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./comp-styles/DesCTA.css";
import SubmitArrow from "../assets/submit-arrow.webp";
import DesCTAModal from "./DesCTAModal"; // Import the modal component
import { Card } from "react-bootstrap";
import { TbBlockquote } from "react-icons/tb";
import HouseSvg from "../assets/house.webp";
import StarSvg from "../assets/star-base.webp";
import GStar from "../assets/star-golden.webp";

/* Description CTA */
const DesCTA = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleFormSubmit = async (values, { resetForm }) => {
    try {
      await axios.post("/leads/desctaleads", values);
      resetForm();
      setModalMessage("We will be in touch with you soon.");
    } catch (error) {
      console.error("Error submitting contact information:", error);
      setModalMessage("Failed to submit your information. Please try again.");
    } finally {
      setIsModalOpen(true); // Show the modal
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const validationSchema = Yup.object().shape({
    contact: Yup.string()
      .matches(
        /^(\+\d{1,3}[- ]?)?\d{10}$|^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid phone number or email address"
      )
      .required("Contact information is required"),
  });

  const quoteText = (
    <>
      <h1 className="index-page-h1">
        Unlock the door to your{" "}
        <span className="quote-section-span">dream home</span> with us!{" "}
        <img src={HouseSvg} alt="House Icon" width="50rem" height="70rem" />{" "}
        <img src={StarSvg} alt="Star Icon" width="50rem" height="70rem" />
      </h1>
      <br />
      <p>
        Discover the joy of hassle-free home buying with our{" "}
        <span className="quote-section-span">zero brokerage</span> policy.{" "}
        <br />
        Your peace of mind matters, and that's why we exclusively deal with{" "}
        <span className="quote-section-span">
          RERA certified
        </span> projects. <br />
        Elevate your living, invest wisely. Your dream home is{" "}
        <span className="quote-section-span">just a few clicks away</span>.{" "}
        <img src={GStar} alt="Golden Star Icon" width="30rem" height="30rem" />{" "}
        <br />
        <span className="quote-section-span">
          #HomeSweetHome #ZeroBrokerage #RERACertified
        </span>
      </p>
    </>
  );

  return (
    <div className="container desCTA-container">
      <div className="left-div">
        <Card
          className="quote-card"
          style={{
            background: "inherit",
            border: "none",
            alignItems: "center",
            padding: "2%",
          }}
        >
          <div className="quote-before">
            <TbBlockquote size="2rem" md={2} className="tbBlockquote" />
          </div>

          <Card.Body className="quote-card-body" style={{ textAlign: "center" }}>
            {quoteText}
          </Card.Body>

          <div className="quote-after">
            <TbBlockquote
              style={{ transform: "scaleX(-1) scaleY(-1)" }}
              size="2rem"
              className="tbBlockquote"
            />
          </div>
        </Card>
      </div>
      <div className="right-div">
        <Formik
          initialValues={{ contact: "" }}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="desCTA-input">
              <Field
                type="text"
                name="contact"
                placeholder="Phone Number or E-mail Id"
                className="desCTA-input-field"
                autoComplete="email tel"
              />
              <ErrorMessage
                name="contact"
                component="div"
                className="error-message"
              />
              <button type="submit" className="desCTA-submit-button" disabled={isSubmitting}>
                <img src={SubmitArrow} alt="Submit" />
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <DesCTAModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        message={modalMessage}
      />
    </div>
  );
};

export default DesCTA;
