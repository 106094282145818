// components/LoadingPage.js
import React from "react";
import "./comp-styles/Loading.css"; 
const LoadingPage = () => {
  return (
    <div className="loading-wrapper">
      <div className="container loader-container">
        <div className="ball-context-layer ball-context-layer--1">
          <div className="ball-container"></div>
        </div>
        <div className="ball-context-layer ball-context-layer--2">
          <div className="ball-container">
            <div className="ball"></div>
          </div>
        </div>
        <div className="ball-context-layer ball-context-layer--3">
          <div className="ball-container">
            <div className="ball"></div>
          </div>
        </div>
        <div className="ball-context-layer ball-context-layer--4">
          <div className="ball-container">
            <div className="ball"></div>
          </div>
        </div>
        <div className="ball-context-layer ball-context-layer--5">
          <div className="ball-container">
            <div className="ball"></div>
          </div>
        </div>
        <div className="ball-context-layer ball-context-layer--6">
          <div className="ball-container">
            <div className="ball"></div>
          </div>
        </div>
        <div className="ball-context-layer ball-context-layer--7">
          <div className="ball-container">
            <div className="ball"></div>
          </div>
        </div>
        <div className="ball-context-layer ball-context-layer--8">
          <div className="ball-container">
            <div className="ball"></div>
          </div>
        </div>
        <div className="ball-context-layer ball-context-layer--9">
          <div className="ball-container">
            <div className="ball"></div>
          </div>
        </div>
        <div className="ball-context-layer ball-context-layer--10">
          <div className="ball-container">
            <div className="ball"></div>
          </div>
        </div>
      </div>
      <div className="loading-text">Loading...</div>
    </div>
  );
};

export default LoadingPage;
