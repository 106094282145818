import { openDB } from "idb";

// Define cache expiration time (e.g., 5 minutes)
const CACHE_EXPIRATION_TIME = 300000; // 5 minutes in milliseconds

const dbPromise = openDB("projectDB", 1, {
  upgrade(db) {
    // Create object store with an index on timestamp for easy querying
    const store = db.createObjectStore("projects", {
      keyPath: "_id",
      autoIncrement: true
    });
    store.createIndex("timestamp", "timestamp");
  },
});

// Function to get project data from IndexedDB
export const getProjectData = async () => {
  const db = await dbPromise;
  const storedData = await db.get("projects", 1); // Assume "1" is the key for your data
  
  if (storedData) {
    const { data, timestamp } = storedData;
    const now = new Date().getTime();
    const dataTimestamp = new Date(timestamp).getTime();
    
    // Check if data is still valid
    if (now - dataTimestamp < CACHE_EXPIRATION_TIME) {
      return data;
    }
  }
  return null;
};

// Function to set project data in IndexedDB
export const setProjectData = async (data) => {
  const db = await dbPromise;
  const timestamp = new Date().toISOString();
  await db.put("projects", { data, timestamp }, 1); // Assume "1" is the key for your data
};

// Function to clear project data from IndexedDB
export const clearProjectData = async () => {
  const db = await dbPromise;
  await db.delete("projects", 1); // Assume "1" is the key for your data
};
