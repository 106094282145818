import "./comp-styles/Navbar.css";
import { useState } from "react";
import BrandLogo from "../assets/Logo.webp";
import ShowLinkIcon from "../assets/link-square.webp";
import HideLinkIcon from "../assets/link-drop.webp";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  return (
    <nav className="navbar navbar-expand-lg  ">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img src={BrandLogo} alt="Brand Logo" width="5rem" height="100%" />
        </Link>
        {showLinks ? (
          <></>
        ) : (
          <ul className="nav-link-list">
            <Link className="nav-links" to="/about">
              About Us
            </Link>
            <Link className="nav-links" to="/contact">
              Contact Us
            </Link>
            <Link className="nav-links" to="/projects">
              Projects
            </Link>
          </ul>
        )}

        <button className="toggle-button" onClick={toggleLinks}>
          <img
            src={showLinks ? ShowLinkIcon : HideLinkIcon}
            alt={showLinks ? "Show Links" : "Hide Links"}
            className={showLinks ? "show-icon" : "hide-icon"}
            width="2.5rem"
            height="100%"
            style={{ background: "transparent" }}
          />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
